.main-nav {
    margin-top: 40px !important;
}

.top-bar .top-bar-cta  {
    margin-top: 2px;
    padding: 0.175rem 0.675rem !important;
}

.side-panel .offcanvas .main-nav{
    font-weight: bold !important;
    opacity: 0.95 !important;
}